import BeginnerIcon from "assets/teacherLp/beginner.svg";
import SproutIcon from "assets/teacherLp/sprout.svg";
import TaskIcon from "assets/teacherLp/task.svg";
import ProcedureImage1 from "assets/teacherLp/procedure-1.png";
import ProcedureImage2 from "assets/teacherLp/procedure-2.png";
import ProcedureImage3 from "assets/teacherLp/procedure-3.png";
import ProcedureImage4 from "assets/teacherLp/procedure-4.png";

export const INTRO_ITEMS = [
  {
    icon: TaskIcon,
    title: "教員免許はなくてOK",
    description: (
      <>
        複業先生ならどなたでも
        <br />
        教育に携わることができます
      </>
    )
  },
  {
    icon: BeginnerIcon,
    title: "事務局の安心サポート",
    description: (
      <>
        事務局による授業づくりの
        <br />
        サポートがあります
      </>
    )
  },
  {
    icon: SproutIcon,
    title: "学んで成長できる",
    description: (
      <>
        養成講座の受講で
        <br />
        授業スキルをアップさせましょう
      </>
    )
  }
];

export const PROCEDURE_ITEMS = [
  {
    image: ProcedureImage1,
    title: "無料登録・審査",
    description:
      "複業先生として活動するため、簡単なプロフィールを記入し会員登録をします。事務局の審査通過後、授業ができるようになります。"
  },
  {
    image: ProcedureImage2,
    title: "依頼がくる / 応募する",
    description:
      "学校から授業依頼が来たり、また自分から興味のある授業に応募することができます。相互で合意すれば授業成立です。"
  },
  {
    image: ProcedureImage3,
    title: "打ち合わせ",
    description:
      "学校と日程調整後、授業に向けて打ち合わせを行います。授業内容などのすり合わせをし、ワクワクする授業を作っていきましょう。"
  },
  {
    image: ProcedureImage4,
    title: "授業実施",
    description:
      "打ち合わせた内容をもとに授業を実施します。授業後はアンケートを記入して、複業先生のお仕事完了です。"
  }
];

export const CASE_ITEMS = [
  {
    videoUrl:
      "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/861.mp4",
    title: "科学を学んだ先にあるキャリア",
    avatarUrl:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/861/4clbsgb8.png",
    organization: "株式会社村田製作所",
    name: "増本優衣"
  },
  {
    videoUrl:
      "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/490.mp4",
    title: "エンジニアってどういう仕事？",
    avatarUrl:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/490/ks9furqd.png",
    organization: "",
    name: "上田晃平"
  },
  {
    videoUrl:
      "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/515.mp4",
    title: "海外大学に進学するには？",
    avatarUrl:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/515/7hkuf2l3.png",
    organization: "株式会社U-LABO",
    name: "小泉涼輔"
  }
];

export const FAQ_ITEMS = [
  {
    question: "教員免許がなくても登録できますか？",
    answer:
      "学校には、​教員免許がなくてもできる仕事がたくさんあります。ICT推進・先生不足が叫ばれる今、ワークシェアが必要です、ぜひお力を貸してください。"
  },
  {
    question: "どのような校種、エリアで授業を行いますか？",
    answer:
      "小中高をはじめどのような校種でも授業を行います。また、オンラインツールを活用し、授業を行うため、エリアも日本全国で実施されています。"
  },
  {
    question: "複業先生になるには審査がありますか？",
    answer:
      "あります。質の担保が目的で、どのようなことに意義を感じて登録したのか、本当に教育に関わっていきたいのか、どんな授業を展開したいのかといったことを重視しています。基準に足りない人にはプロフィールを修正していただいたり、ときには登録をお断りしたりすることもあります。また、授業登壇前の学びの場として養成講座も準備しています。"
  },
  {
    question: "謝金はどのようなシステムになっていますか？",
    answer:
      "授業発注時に確定するので、そちらでご参照ください。ランクにより謝金額は変動します。"
  }
];

export const TEACHERS_ITEMS = [
  {
    teachersVoice:
      "当日は、目を輝かせて話を聞いてくれる姿に感動し、「自分の経験が誰かの背中を押す力になれる」と改めて実感しました。先生方や生徒たちからもらった感想の数々は、いまの私の原動力となっています。",
    avatar:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/1844/3lgbhdnu.png",
    titles: ["合同会社UpSpire"],
    name: "渡邊千紘"
  },
  {
    teachersVoice:
      "「自分を大切にしながら相手も尊重する」そんな事を授業を通してお伝えしています。複業先生のお陰で、1人では到底出会えなかった地域の子ども達へ授業させてもらてる事がとても嬉しいですし、わたし自身の大きな学びにもなっています。",
    avatar:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/1751/ggui1dyo.png",
    titles: [""],
    name: "杉木紗季	"
  },
  {
    teachersVoice:
      "「ずっと先生になりたいっ！！」そう思っていた僕の夢を叶えてくれた複業先生。毎回生徒さんたちの背中を押せたらと思いながら授業に臨んで、逆にエネルギーをもらってくることもしばしばです。笑",
    avatar:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/2108/srt2o4o6.png",
    titles: ["幸海ヒーローズ"],
    name: "富本龍徳"
  },
  {
    teachersVoice:
      "高校でのプレゼンテーション授業を担当し、生徒たちの熱意と創造性に心を打たれました。「誰に」「何を」「どのように」というフレームワークを伝え、内容をブラッシュアップした際、アイデアがより具体化していく過程での生徒たちの目の輝きは、何よりの喜びでした。",
    avatar:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/2226/profile.png",
    titles: ["駐妻キャリアnet代表"],
    name: "三浦梓"
  },
  {
    teachersVoice:
      "自分のリアルな実務経験を実務家教員として学生達に伝えられること、小学校や高等学校など学校種に限定がなく様々な場所で講師経験を積めること、講義形式やお題など状況に合わせて講義内容を考えていくこと、等にやり甲斐を感じています。",
    avatar:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/928/asnj3vz4.png",
    titles: [""],
    name: "山﨑実香"
  }
];
