import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import { Methods as LessonRequestCommentsMethods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import { Methods as LessonMeetingPossibleDatimesMethods } from "api/api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes";
import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";

import { currentSchoolAtom } from "store/SchoolAuthStore";

import useHeaders from "hooks/useHeaders";
import useSchoolTeacherCommonData from "hooks/useSchoolTeacherCommonData";
import { useAlertMessages } from "hooks/useAlertMessages";
import {
  detailsAtom,
  teacherAtom,
  supporterAtom,
  lessonRequestCommentsAtom,
  meetingSheetInLessonAtom,
  ratingsAtom
} from "./store";
import { postRatingsSchemaType } from "./constants/postRatingsSchema";

type postRequestCommentsType = LessonRequestCommentsMethods["post"]["reqBody"];
type postLessonMeetingPossibleDatimesType =
  LessonMeetingPossibleDatimesMethods["post"]["reqBody"];

const useShowPageApi = (lessonId: string) => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const currentSchool = useAtom(currentSchoolAtom)[0];
  const [details, setDetails] = useAtom(detailsAtom);
  const [, setTeacher] = useAtom(teacherAtom);
  const [, setSupporter] = useAtom(supporterAtom);
  const [, setMeetingSheetInLesson] = useAtom(meetingSheetInLessonAtom);
  const [, setRequestComments] = useAtom(lessonRequestCommentsAtom);
  const [, setRatings] = useAtom(ratingsAtom);

  const { mutate: mutateSchoolTeacherCommonData } =
    useSchoolTeacherCommonData();

  const { isLoading: getLessonIsLoading, mutate: mutateLessonData } =
    useAspidaSWR(
      // eslint-disable-next-line no-underscore-dangle
      aspida(headers)
        .api.v1.school_teachers._schoolTeacherId_string(
          currentSchool.id.toString()
        )
        .lessons._lessonId(lessonId),
      {
        onSuccess: (data) => {
          setDetails(data.details);
          setTeacher(data.teacher);
          setSupporter(data.supporter);
          setMeetingSheetInLesson(data.agenda_sheet);
        },
        onError: () => {
          addErrorMessage("授業情報の取得に失敗しました");
        },
        enabled: !!currentSchool.id
      }
    );

  const updateLesson = async (
    data: UpdateLessonMethods["patch"]["reqBody"]
  ) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .patch({ body: data });
      await mutateLessonData();
      // MEMO: データの送り方に合わせてメッセージを変更
      if (data.is_skip_meeting) {
        addSuccessMessage("授業の打ち合わせをスキップしました");
      }
      if (data.meeting_start_at) {
        addSuccessMessage("授業の打ち合わせ日程を登録しました");
      }
      if (data.meeting_place || data.meeting_url) {
        addSuccessMessage("打ち合わせ場所を登録しました");
      }
      if (data.is_skip_lesson_preliminary_report) {
        addSuccessMessage("授業前アンケートをスキップしました");
      }
      if (data.lesson_preliminary_report_completed_at) {
        addSuccessMessage("授業前アンケートを完了しました");
      }
    } catch (error) {
      addErrorMessage("授業情報の更新に失敗しました");
    }
  };

  // for chat
  const {
    isLoading: getRequestCommentsIsLoading,
    mutate: mutateRequestComments
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lesson_requests._lessonRequestId(
      details?.lesson_request_id?.toString() || ""
    ).lesson_request_comments,
    {
      refreshInterval: 60000,
      onSuccess: (data) => {
        setRequestComments(data);
      },
      // MEMO: 必ず1度目にエラーが出るため一旦コメントアウト
      onError: () => addErrorMessage("メッセージの取得に失敗しました"),
      enabled: !!details?.lesson_request_id
    }
  );

  const postRequestComments = async (data: postRequestCommentsType) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId(
          details?.lesson_request_id?.toString() || ""
        )
        .lesson_request_comments.post({
          body: data
        });

      await mutateRequestComments();
      addSuccessMessage("メッセージを送信しました");
    } catch (error) {
      addErrorMessage("メッセージの送信に失敗しました");
    }
  };

  const deleteRequestComments = async (commentId: string) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(
          details?.lesson_request_id?.toString() || ""
        )
        .lesson_request_comments._lessonRequestCommentId(commentId)
        .logical_delete.patch({});

      await mutateRequestComments();

      addSuccessMessage("メッセージを削除しました");
    } catch (error) {
      addErrorMessage("メッセージの削除に失敗しました");
    }
  };

  const logicalDeleteUnreadMessages = async () => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .unread_messages.multi_update.put({ body: { is_read: true } });

      await mutateSchoolTeacherCommonData();
      await mutateRequestComments();
    } catch (error) {
      console.error(error);
    }
  };

  // for rating
  const { mutate: mutateRating } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lessons._lessonId_string(lessonId).ratings,
    {
      onSuccess: (data) => setRatings(data.details),
      // MEMO: 今後リクエストのリトライ処理は共通化する
      onErrorRetry: (err, key, config, revalidate, revalidateOpts) => {
        if (err.response.status === 404) return;

        // MEMO: 3回リトライしてもダメならエラーメッセージを表示
        if (revalidateOpts.retryCount >= 3) {
          addErrorMessage("評価情報の取得に失敗しました");
          return;
        }

        setTimeout(() => revalidate(revalidateOpts), 1000);
      },
      onError: () => {
        setRatings(undefined);
      }
    }
  );

  // for meeting schedules
  const postMeetingSchedules = async (
    data: postLessonMeetingPossibleDatimesType
  ) => {
    try {
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .lesson_meeting_possible_datetimes.post({ body: data });
      await mutateLessonData();

      addSuccessMessage("授業の打ち合わせ日程を登録しました");
    } catch (error) {
      addErrorMessage("授業の打ち合わせ日程の登録に失敗しました");
    }
  };

  const postRating = async (data: postRatingsSchemaType) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .ratings.post({
          body: data
        });
      await mutateRating();
      await mutateLessonData();
      addSuccessMessage("評価を送信しました");
    } catch (error) {
      console.error(error);
      addErrorMessage("評価の送信に失敗しました");
    }
  };

  return {
    getLessonIsLoading,
    getRequestCommentsIsLoading,
    mutateRequestComments,
    postRequestComments,
    deleteRequestComments,
    logicalDeleteUnreadMessages,
    postMeetingSchedules,
    updateLesson,
    postRating
  };
};

export default useShowPageApi;
