import { atom } from "jotai";
import { Methods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts";
import { Methods as MethodsForData } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts/_teacherBankAccountId@string/edit";

type TeacherBankAccountType = Methods["get"]["resBody"]["teacher_bank_account"];
type TeacherBankAccountDataType =
  MethodsForData["get"]["resBody"]["teacher_bank_account"];
type BankAccountTypesType = Methods["get"]["resBody"]["bank_account_types"];

export const teacherBankAccountAtom = atom<TeacherBankAccountType | null>(null);
export const bankAccountTypesAtom = atom<BankAccountTypesType>([]);

// MEMO: 編集状況に併せてリクエストを投げるためにstoreに持たせる
export const isEditingAtom = atom<boolean>(false);
export const setForDisplayAtom = atom(null, (get, set) => {
  set(isEditingAtom, false);
});
export const setForEditAtom = atom(null, (get, set) => {
  set(isEditingAtom, true);
});
