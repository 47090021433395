import Meta from "components/organisms/Meta";
import Conclusion from "features/root/index/organisms/Conclusion";
import FirstView from "features/root/index/organisms/FirstView";
import Intro from "features/root/index/organisms/Intro";
import Procedures from "features/root/index/organisms/Procedures";
import TeachersBox from "features/root/index/organisms/TeachersBox";
import CommonLayout from "components/layouts/CommonLayout";
import Case from "features/root/index/organisms/Case";

import type React from "react";
import useAlertToNew from "hooks/useAlertToNew";
import Faq from "./organisms/Faq";

const Home: React.FC = () => {
  useAlertToNew();

  return (
    <CommonLayout>
      <Meta />
      <FirstView />
      <Intro />
      <Procedures />
      <TeachersBox />
      <Case />
      <Faq />
      <Conclusion />
    </CommonLayout>
  );
};

export default Home;
