import { Stack, Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  icon: string;
  title: string;
  description: string | ReactNode;
}

const IntroItem: React.FC<Props> = (props) => (
  <Stack direction="row" spacing={2}>
    <Box
      height="72px"
      width="72px"
      alignItems="center"
      justifyContent="center"
      sx={{
        display: "flex",
        backgroundColor: "#FFF6DE",
        borderRadius: "100%",
        flexShrink: 0
      }}
    >
      <img src={props.icon} alt="" height="40px" />
    </Box>
    <Stack spacing={0.5}>
      <Typography variant="h4">{props.title}</Typography>
      <Typography>{props.description}</Typography>
    </Stack>
  </Stack>
);

export default IntroItem;
