import { useAtomValue, useAtom } from "jotai";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";
import Button from "components/atoms/mui/Button";

import { teacherBankAccountAtom, setForEditAtom } from "../store";

type BankAccountDisplayProps = {
  handleDelete: () => void;
};

const BankAccountDisplay: React.FC<BankAccountDisplayProps> = (props) => {
  const { handleDelete } = props;
  const teacherBankAccount = useAtomValue(teacherBankAccountAtom);
  const [, setForEdit] = useAtom(setForEditAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <Typography>登録済みの銀行口座</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem"
        }}
      >
        <Typography fontSize="1.1rem" fontWeight="bold">
          {teacherBankAccount?.financial_institution_name}***
          {teacherBankAccount?.account_number_last_four}
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={setForEdit}
            sx={{
              padding: "0 .5rem"
            }}
          >
            編集する
          </Button>
          <Button isLink onClick={handleDelete}>
            削除する
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BankAccountDisplay;
