import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import type React from "react";
import { customColors } from "themes/customColors";
import { Box } from "@mui/material";

type TeacherBoxProps = {
  teachersVoice: string;
  avatar: string;
  titles: string[];
  name: string;
};

const TeacherBox: React.FC<TeacherBoxProps> = (props) => {
  const { teachersVoice, avatar, titles, name } = props;

  return (
    <Box maxWidth="360px" m="1rem">
      <Box
        borderRadius="32px 32px 32px 0"
        p="1.5rem"
        bgcolor="#ffffff"
        minHeight={{ xs: "285px", sm: "200px" }}
      >
        <Typography variant="body2">{teachersVoice}</Typography>
      </Box>
      <Box margin="1rem auto" sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt={`${name}avatar`}
          src={avatar}
          sx={{ width: "4rem", height: "4rem" }}
        />
        <Box margin="0 1rem">
          <Typography
            sx={{
              fontSize: "0.9rem !important",
              lineHeight: "1.2 !important",
              color: customColors.textColor.sub
            }}
          >
            {titles.map((element) => (
              <>
                <span key={element}>{element}</span>
                <br />
              </>
            ))}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TeacherBox;
