import * as yup from "yup";
import { Methods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts/_teacherBankAccountId@string";
import {
  accountNumberSchema,
  katakanaSchema,
  invoiceRegistrationNumberSchema
} from "utils/yupUtils";

const updateBankAccountSchema: yup.ObjectSchema<Methods["put"]["reqBody"]> = yup
  .object()
  .shape({
    financial_institution_name: yup.string().required("必須の項目です"),
    branch_name: yup.string().required("必須の項目です"),
    // 数字のみの入力を許可する
    account_number: accountNumberSchema.required("必須の項目です"),
    bank_account_type_id: yup.number().required("必須の項目です"),
    account_name: katakanaSchema.required("必須の項目です"),
    financial_institution_code: yup.string().optional(),
    branch_code: yup.string().optional(),
    invoice_registration_number: invoiceRegistrationNumberSchema.optional()
  });

export default updateBankAccountSchema;
