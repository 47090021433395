import Typography from "@mui/material/Typography";

import type React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import ManImage from "assets/ManImage.png";
import WomanImage from "assets/WomanImage.png";
import Button from "components/atoms/mui/Button";
import { useHistory } from "react-router-dom";

const Conclusion: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const underLg = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <section>
      <Box
        sx={{
          background: "linear-gradient(to right, #FFEFC7, #FFDBAA)",
          padding: "calc(3rem + .25vw) 0 2rem",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "80%",
            maxWidth: "960px",
            margin: "0 auto",
            marginBottom: underLg ? "120px" : "0"
          }}
        >
          <Typography
            variant="h3"
            align="center"
            fontSize={{ xs: "1.5rem !important", md: "1.8rem !important" }}
            sx={{
              fontSize: { xs: "1.5rem !important", md: "1.8rem !important" },
              lineHeight: "2 !important"
            }}
          >
            <Box sx={{ display: "inline-block" }}>教員経験がなくてもOK！</Box>
            <br />
            <Box sx={{ display: "inline-block" }}>“複業先生”になって、</Box>
            <Box sx={{ display: "inline-block" }}>学校を支援しませんか？</Box>
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => history.push("/teachers/signup")}
              sx={{
                display: "block",
                margin: "1.5rem auto",
                width: "90%",
                maxWidth: "480px",
                padding: "calc(1rem + .3vw)"
              }}
            >
              新規登録する
            </Button>
          </Box>

          <Box
            component="img"
            alt="man"
            src={ManImage}
            sx={{
              display: "block",
              position: "absolute",
              top: underLg ? "80%" : "30%",
              left: { xs: "calc(-9vw - 9rem)", lg: "calc(-20%)" },
              zIndex: 1
            }}
          />
          <Box
            component="img"
            alt="woman"
            src={WomanImage}
            sx={{
              display: "block",
              position: "absolute",
              top: underLg ? "80%" : "30%",
              right: { xs: "calc(-9vw - 9rem)", lg: "calc(-20%)" },
              zIndex: 1
            }}
          />
        </Box>
      </Box>
    </section>
  );
};

export default Conclusion;
