import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import useHeaders from "hooks/useHeaders";

import { useAlertMessages } from "hooks/useAlertMessages";
import { Methods as UpdateProfileMethods } from "api/api/v1/teacher_profiles/_teacherProfileId@string";
import { currentUserAtom } from "store/AuthStore";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  sendFamilyRegisterNameSchema,
  updateBirthdaySchema,
  updateEmailSchema,
  updatePasswordSchema
} from "constants/AuthFormSchema";
import { useDialog } from "hooks/useDialog";
import {
  teacherBankAccountAtom,
  bankAccountTypesAtom,
  isEditingAtom,
  setForDisplayAtom
} from "./store";
import createBankAccountSchema from "./constants/createBankAccountSchema";
import updateBankAccountSchema from "./constants/updateBankAccountSchema";

const useSettingsPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const [teacherBankAccount, setTeacherBankAccount] = useAtom(
    teacherBankAccountAtom
  );
  const [, setBankAccountTypes] = useAtom(bankAccountTypesAtom);
  const [isEditing] = useAtom(isEditingAtom);
  const [, setForDisplay] = useAtom(setForDisplayAtom);

  const id = currentUser.teacher_profile_id.toString();
  const sendFamilyRegisterNameModal = useDialog();
  const updateProfileForm = useForm<UpdateProfileMethods["put"]["reqBody"]>();
  const updatePasswordForm = useForm({
    resolver: yupResolver(updatePasswordSchema),
    mode: "all"
  });
  const updateEmailForm = useForm({
    resolver: yupResolver(updateEmailSchema),
    mode: "all"
  });
  const createBankAccountForm = useForm({
    resolver: yupResolver(createBankAccountSchema),
    mode: "all"
  });

  const updateBankAccountForm = useForm({
    resolver: yupResolver(updateBankAccountSchema),
    mode: "all"
  });

  const updateBirthdayForm = useForm({
    resolver: yupResolver(updateBirthdaySchema),
    mode: "all"
  });
  const sendFamilyRegisterNameForm = useForm({
    resolver: yupResolver(sendFamilyRegisterNameSchema),
    mode: "all"
  });

  const {
    data: profileData,
    isLoading: profileIsLoading,
    mutate: profileMutate
  } = useAspidaSWR(
    aspida(headers).api.v1.teacher_profiles._teacherProfileId(id).edit,
    {
      onError: () => {
        addErrorMessage("アカウント設定の取得に失敗しました");
      }
    }
  );

  const { mutate: bankAccountMutate, isLoading: bankAccountLoading } =
    useAspidaSWR(
      aspida(headers).api.v1.teachers._teacherId_string(id)
        .teacher_bank_accounts,
      {
        onSuccess: (data) => {
          setTeacherBankAccount(data.teacher_bank_account);
          setBankAccountTypes(data.bank_account_types);
        },
        onError: () => {
          addErrorMessage("口座情報の取得に失敗しました");
        }
      }
    );

  const { data: teacherBankAccountData, isLoading: bankAccountEditLoading } =
    useAspidaSWR(
      aspida(headers)
        .api.v1.teachers._teacherId_string(id)
        .teacher_bank_accounts._teacherBankAccountId(
          teacherBankAccount?.id.toString() || ""
        ).edit,
      {
        onSuccess: (data) => {
          setBankAccountTypes(data.bank_account_types);
        },
        onError: () => {
          addErrorMessage("口座情報の取得に失敗しました");
        },
        enabled: !!teacherBankAccount && isEditing
      }
    );

  useEffect(() => {
    if (profileData) {
      updateProfileForm.reset({
        is_limited_public: profileData.details.is_limited_public,
        is_reward: profileData.details.is_reward,
        can_interview: profileData.details.can_interview,
        is_mail_magazine: profileData.details.is_mail_magazine
      });
      sendFamilyRegisterNameForm.reset({
        family_register_name: profileData.details.family_register_name
      });
    }
  }, [profileData]);

  useEffect(() => {
    if (teacherBankAccountData) {
      updateBankAccountForm.reset({
        financial_institution_name:
          teacherBankAccountData.teacher_bank_account
            .financial_institution_name,
        branch_name: teacherBankAccountData.teacher_bank_account.branch_name,
        account_number:
          teacherBankAccountData.teacher_bank_account.account_number,
        bank_account_type_id:
          teacherBankAccountData.teacher_bank_account.bank_account_type_id,
        account_name: teacherBankAccountData.teacher_bank_account.account_name,
        financial_institution_code:
          teacherBankAccountData.teacher_bank_account
            .financial_institution_code,
        branch_code: teacherBankAccountData.teacher_bank_account.branch_code,
        invoice_registration_number:
          teacherBankAccountData.teacher_bank_account
            .invoice_registration_number
      });
    }
  }, [teacherBankAccountData]);

  const updateProfile = async () => {
    try {
      const params = updateProfileForm.getValues();
      const response = await aspida(headers)
        .api.v1.teacher_profiles._teacherProfileId(id)
        .put({
          body: params
        });
      if (response.status === 200) {
        // is_rewardを更新
        setCurrentUser({
          ...currentUser,
          is_reward: params?.is_reward || false
        });
        addSuccessMessage("アカウント設定を保存しました");
        profileMutate();
      } else {
        addErrorMessage("アカウント設定の更新に失敗しました");
      }
    } catch {
      addErrorMessage("アカウント設定の更新に失敗しました");
    }
  };

  const updatePassword = async () => {
    try {
      const params = updatePasswordForm.getValues();
      const response = await aspida(headers).api.v1.teacher_auth.patch({
        body: params
      });
      if (response.status === 200) {
        addSuccessMessage("パスワードを変更しました");
      } else {
        addErrorMessage("パスワードの変更に失敗しました");
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors?.full_messages[0] ||
        "パスワードの変更に失敗しました";
      addErrorMessage(errorMessage);
    }
  };

  const updateEmail = async () => {
    try {
      const params = updateEmailForm.getValues();
      const response = await aspida(headers).api.v1.teacher_auth.patch({
        body: params
      });
      if (response.status === 200) {
        addSuccessMessage("確認メールを送信しました");
      } else {
        addErrorMessage("メールアドレスの変更に失敗しました");
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors?.full_messages[0] ||
        "メールアドレスの変更に失敗しました";
      addErrorMessage(errorMessage);
    }
  };

  const createBankAccount = async () => {
    try {
      const params = createBankAccountForm.getValues();
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(id)
        .teacher_bank_accounts.post({
          body: params
        });
      if (response.status === 200) {
        addSuccessMessage("口座情報を登録しました");
        createBankAccountForm.reset();
        bankAccountMutate();
      } else {
        addErrorMessage("口座情報の登録に失敗しました");
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors?.full_messages[0] ||
        "口座情報の登録に失敗しました";
      addErrorMessage(errorMessage);
    }
  };

  const updateBankAccount = async () => {
    try {
      const bankAccountId = teacherBankAccount?.id.toString();
      if (!bankAccountId) {
        addErrorMessage("口座情報の更新に失敗しました");
        return;
      }
      const params = updateBankAccountForm.getValues();
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(id)
        .teacher_bank_accounts._teacherBankAccountId(bankAccountId)
        .put({
          body: params
        });
      if (response.status === 200) {
        addSuccessMessage("口座情報を更新しました");
        bankAccountMutate();
        setForDisplay();
      } else {
        addErrorMessage("口座情報の更新に失敗しました");
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors?.full_messages[0] ||
        "口座情報の更新に失敗しました";
      addErrorMessage(errorMessage);
    }
  };

  const deleteBankAccount = async () => {
    try {
      const bankAccountId = teacherBankAccount?.id.toString();
      if (!bankAccountId) {
        addErrorMessage("口座情報の削除に失敗しました");
        return;
      }
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(id)
        .teacher_bank_accounts._teacherBankAccountId(bankAccountId)
        .delete();
      if (response.status === 200) {
        addSuccessMessage("口座情報を削除しました");
        bankAccountMutate();
      } else {
        addErrorMessage("口座情報の削除に失敗しました");
      }
    } catch {
      addErrorMessage("口座情報の削除に失敗しました");
    }
  };

  const updateBirthday = async () => {
    try {
      const params = updateBirthdayForm.getValues();
      const response = await aspida(headers)
        .api.v1.teacher_profiles._teacherProfileId(id)
        .put({
          body: params
        });
      if (response.status === 200) {
        setCurrentUser({
          ...currentUser,
          has_birthday: true
        });
        addSuccessMessage("生年月日を更新しました");
        profileMutate();
      } else {
        addErrorMessage("生年月日の更新に失敗しました");
      }
    } catch {
      addErrorMessage("生年月日の更新に失敗しました");
    }
  };

  const updateFamilyRegisterName = async () => {
    try {
      const params = sendFamilyRegisterNameForm.getValues();
      const response = await aspida(headers)
        .api.v1.teacher_profiles._teacherProfileId(id)
        .put({
          body: params
        });
      if (response.status === 200) {
        addSuccessMessage("本名を更新しました");
        profileMutate();
        sendFamilyRegisterNameModal.onClose();
      } else {
        addErrorMessage("本名の更新に失敗しました");
      }
    } catch {
      addErrorMessage("本名の更新に失敗しました");
    }
  };

  const postIdentification = async () => {
    try {
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(id)
        .teacher_identifications.post();
      if (response.status === 200) {
        const publicId = response.body.identification_public_id;
        const trustdock = new Trustdock({
          publicId,
          plans: [
            {
              id: process.env.REACT_APP_TRUSTDOCK_PLAN_ID || ""
            }
          ]
        });

        // trustdockがロードされたらopen()を実行
        if (trustdock.isLoaded) {
          trustdock.open();
        } else {
          trustdock.on("loaded", () => {
            trustdock.open();
          });
        }

        trustdock.on("documentsubmitted", () => {
          setCurrentUser({
            ...currentUser,
            is_identification_in_progress: true
          }); // 明示的に本人確認中にする
        });
      } else {
        addErrorMessage("本人確認に失敗しました。");
      }
    } catch {
      addErrorMessage("本人確認に失敗しました。");
    }
  };

  return {
    updateProfile,
    profileData,
    profileIsLoading,
    updateProfileForm,
    updatePassword,
    updatePasswordForm,
    updateEmail,
    updateEmailForm,
    createBankAccount,
    createBankAccountForm,
    updateBankAccount,
    updateBankAccountForm,
    deleteBankAccount,
    bankAccountLoading,
    bankAccountEditLoading,
    updateBirthday,
    updateBirthdayForm,
    postIdentification,
    sendFamilyRegisterNameForm,
    updateFamilyRegisterName,
    sendFamilyRegisterNameModal
  };
};

export default useSettingsPageApi;
