import React, { useEffect, useRef } from "react";
import { useAtomValue } from "jotai";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ForumIcon from "@mui/icons-material/Forum";
import Typography from "@mui/material/Typography";

import { teacherCommonDataAtom } from "store/TeacherCommonDataStore";
import { schoolTeacherCommonDataAtom } from "store/SchoolTeacherCommonDataStore";

import HeaderNoticeItem from "./HeaderNoticeItem";

const HeaderNotices: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const teacherCommonData = useAtomValue(teacherCommonDataAtom);
  const schoolTeacherCommonData = useAtomValue(schoolTeacherCommonDataAtom);

  const chatNotices =
    teacherCommonData?.chat_notices || schoolTeacherCommonData?.chat_notices;
  const unreadMessagesCount =
    teacherCommonData?.unread_messages_count ||
    schoolTeacherCommonData?.unread_messages_count;

  const [open, setOpen] = React.useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
      >
        <IconButton onClick={handleToggle} size={isMobile ? "small" : "medium"}>
          <Badge
            badgeContent={unreadMessagesCount}
            color="primary"
            sx={
              isMobile
                ? {
                    "& .MuiBadge-badge": {
                      fontSize: 9,
                      height: 15,
                      minWidth: 15
                    }
                  }
                : undefined
            }
          >
            <ForumIcon fontSize={isMobile ? "small" : "medium"} />
          </Badge>
        </IconButton>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper
              sx={{
                width: 270
              }}
            >
              <Typography
                fontWeight="bold"
                align="center"
                sx={{ padding: ".5rem 1rem 0" }}
              >
                新着メッセージ一覧
              </Typography>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {!chatNotices?.length ? (
                    <Box>
                      <MenuItem disabled>
                        <ListItemText>
                          新着メッセージはありません。
                        </ListItemText>
                      </MenuItem>
                    </Box>
                  ) : (
                    chatNotices?.map((chatNotice, index) => (
                      <HeaderNoticeItem key={index} chatNotice={chatNotice} />
                    ))
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default HeaderNotices;
