import { useWatch } from "react-hook-form";

import Box from "@mui/material/Box";
import BankAccountField from "components/molecules/BankAccountField";
import FormTextField from "components/molecules/FormTextField";

import { useAtomValue } from "jotai";

import type React from "react";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { bankAccountTypesAtom } from "../store";

const BankAccountCreateForm: React.FC = () => {
  const institutionCode = useWatch({ name: "financial_institution_code" });
  const bankAccountTypes = useAtomValue(bankAccountTypesAtom);
  const bankAccountTypeOptions = bankAccountTypes.map((type) => ({
    value: type.id.toString(),
    label: type.name
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <BankAccountField
        name="financial_institution_name"
        codeName="financial_institution_code"
        label="金融機関名"
        type="bank"
        isRequired
      />
      <BankAccountField
        name="branch_name"
        codeName="branch_code"
        label="支店名"
        type="branch"
        institutionCode={institutionCode}
        isRequired
      />
      <FormTextField name="account_number" label="口座番号" isRequired />
      <FormRadioGroup
        name="bank_account_type_id"
        label="口座種別"
        options={bankAccountTypeOptions}
        isRequired
      />
      <FormTextField
        name="account_name"
        label="口座名義（カタカナ）"
        supplementalText="※銀行口座はご本人名義の口座のみご利用いただけます。"
        isRequired
      />
      <FormTextField
        name="invoice_registration_number"
        label="インボイス登録番号"
        supplementalText="※インボイス事業者として登録されている方のみ入力してください。"
      />
      <FormSubmitButton label="登録する" fullWidth size="large" />
    </Box>
  );
};

export default BankAccountCreateForm;
