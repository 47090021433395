import Typography from "@mui/material/Typography";

import LeafLeft from "assets/teacherLp/leaf-left.svg";
import LeafRight from "assets/teacherLp/leaf-right.svg";

import IntroLessonImage from "assets/teacherLp/intro-lesson.png";

import type React from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import IntroItem from "../molecules/IntroItem";
import { INTRO_ITEMS } from "../constants";

const Intro: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const leafImageStyle = {
    width: isMobile ? "19px" : "24px",
    height: isMobile ? "45px" : "58px"
  };

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: 3,
        alignItems: "center",
        justifyContent: "center",
        mb: isMobile ? "48px" : "120px"
      }}
    >
      <Stack
        direction={
          useMediaQuery(theme.breakpoints.down(950)) ? "column" : "row"
        }
        spacing={3}
        alignItems="center"
        maxWidth={960}
      >
        {/* テキスト部分 */}
        <Stack spacing={2} sx={{ flex: 1, minWidth: "0" }}>
          <Typography variant="h3" lineHeight="180%">
            自分の知識・経験を活かして、
            <br />
            生徒に新しい学びを提供できます
          </Typography>
          <Typography variant="body2" lineHeight="200%">
            これまでの仕事や経験を学校現場で分かち合いたい人がスポットで「先生」の仕事ができる、教育特化型の複業案件プラットフォームです。「教育現場に関わりたい」「専門性を教育に活かしたい」と思っている皆様をサポートします。
          </Typography>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1.5}>
              {/* 左のデータ表示 */}
              <Stack direction="row" spacing={1}>
                <img src={LeafLeft} alt="leaf-left" style={leafImageStyle} />
                <Stack direction="column" alignItems="center">
                  <Typography
                    fontWeight="bold"
                    fontSize={{ xs: "12px", sm: "14px" }}
                  >
                    複業先生登録者
                  </Typography>
                  <Typography color="primary">
                    <span
                      style={{
                        fontSize: isMobile ? "28px" : "32px",
                        fontWeight: "800"
                      }}
                    >
                      2,459
                    </span>
                    <span
                      style={{
                        fontSize: isMobile ? "18px" : "24px",
                        fontWeight: "800"
                      }}
                    >
                      名
                    </span>
                  </Typography>
                </Stack>
                <img src={LeafRight} alt="leaf-right" style={leafImageStyle} />
              </Stack>
              {/* 右のデータ表示 */}
              <Stack direction="row" spacing={1}>
                <img src={LeafLeft} alt="leaf-left" style={leafImageStyle} />
                <Stack direction="column" alignItems="center">
                  <Typography
                    fontWeight="bold"
                    fontSize={{ xs: "12px", sm: "14px" }}
                  >
                    授業参加生徒
                    <span
                      style={{
                        fontSize: isMobile ? "11px" : "14px",
                        fontWeight: "normal"
                      }}
                    >
                      （累計）
                    </span>
                  </Typography>
                  <Typography color="primary">
                    <span
                      style={{
                        fontSize: isMobile ? "25px" : "32px",
                        fontWeight: "800"
                      }}
                    >
                      25,629
                    </span>
                    <span
                      style={{
                        fontSize: isMobile ? "18px" : "24px",
                        fontWeight: "800"
                      }}
                    >
                      名
                    </span>
                  </Typography>
                </Stack>
                <img src={LeafRight} alt="leaf-right" style={leafImageStyle} />
              </Stack>
            </Stack>
            <Typography variant="caption">※2024年12月時点</Typography>
          </Stack>
        </Stack>

        {/* 画像部分 */}
        <Box
          sx={{
            flex: "0 1 50%", // 画像が縮小可能だが50%の最大幅を維持
            maxWidth: "420px", // 最大幅を指定
            minWidth: "240px", // 最小幅を指定
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src={IntroLessonImage}
            alt="intro-lesson"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain"
            }}
          />
        </Box>
      </Stack>

      <Stack
        direction={isMobile ? "column" : "row"}
        gap={3}
        width="100%"
        maxWidth={960}
      >
        {INTRO_ITEMS.map((item, index) => (
          <IntroItem key={index} {...item} />
        ))}
      </Stack>
    </Box>
  );
};

export default Intro;
