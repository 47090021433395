import React from "react";
import { useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import { formatDateTime } from "utils/date";
import { shortSentences } from "utils/text";

import { Methods as SchoolTeacherMethods } from "api/api/v1/tools/school_teacher_common_data";
import { Methods as TeacherMethods } from "api/api/v1/tools/teacher_common_data";

type HeaderNoticeItemProps = {
  chatNotice:
    | NonNullable<TeacherMethods["get"]["resBody"]["chat_notices"]>[number]
    | NonNullable<
        SchoolTeacherMethods["get"]["resBody"]["chat_notices"]
      >[number];
};

const HeaderNoticeItem: React.FC<HeaderNoticeItemProps> = (props) => {
  const { chatNotice } = props;
  const history = useHistory();
  const theme = useTheme();
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const sendUserDisplay = () => {
    switch (chatNotice.unread_latest_message.send_user?.category) {
      case "teacher":
        return chatNotice.unread_latest_message.send_user.name;
      case "school_teacher":
        return `${chatNotice.unread_latest_message.send_user.school_name}：\n${chatNotice.unread_latest_message.send_user.name}`;
      default:
        return `事務局：${chatNotice.unread_latest_message.send_user?.name}`;
    }
  };

  const handleToUnreadMessage = (lessonId: number) => {
    if (isLoggedIn)
      history.push(`/teachers/mypage/lessons/${lessonId}?tab_name=chat`);
    if (schoolIsLoggedIn)
      history.push(`/schools/mypage/lessons/${lessonId}?tab_name=chat`);
  };

  return (
    <MenuItem onClick={() => handleToUnreadMessage(chatNotice.lesson_id)}>
      {chatNotice.is_read || (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: 8,
            width: 8,
            height: 8,
            borderRadius: "50%",
            transform: "translateY(-50%)",
            backgroundColor: theme.palette.error.main
          }}
        />
      )}
      <ListItemIcon>
        <AvatarGroup
          max={2}
          spacing={24}
          sx={{
            minWidth: 60,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {chatNotice.send_users?.map((send_user, index) => (
            <Avatar
              key={index}
              alt={send_user.name}
              src={send_user.image.url}
              sx={{
                width: 36,
                height: 36
              }}
            />
          ))}
        </AvatarGroup>
      </ListItemIcon>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%"
        }}
      >
        <Typography fontSize="0.8rem" sx={{ whiteSpace: "pre-wrap" }}>
          {sendUserDisplay()}
        </Typography>
        <Typography fontSize="0.8rem">
          {shortSentences(chatNotice.unread_latest_message?.comment || "", 10)}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            color: theme.palette.text.secondary,
            width: "100%"
          }}
        >
          {formatDateTime(chatNotice.unread_latest_message?.created_at)}
        </Typography>
      </Box>
    </MenuItem>
  );
};

export default HeaderNoticeItem;
