import { Stack, Box, Typography } from "@mui/material";
import { VideoJS } from "components/atoms/VideoJS";
import type videojs from "video.js";
import Avatar from "@mui/material/Avatar";

import type React from "react";
import { customColors } from "themes/customColors";

interface CaseItemProps {
  videoUrl: string;
  title: string;
  avatarUrl: string;
  organization: string;
  name: string;
}

const CaseItem: React.FC<CaseItemProps> = ({
  title,
  avatarUrl,
  organization,
  videoUrl,
  name
}) => {
  const videoJSOptions: videojs.PlayerOptions = {
    controls: true,
    aspectRatio: "16:9",
    fluid: true,
    sources: [
      {
        src: videoUrl,
        type: "video/mp4"
      }
    ]
  };
  return (
    <Stack spacing={3} width="100%">
      <Box sx={{ width: "100%" }}>
        <VideoJS options={videoJSOptions} />
      </Box>
      <Stack spacing={1}>
        <Typography variant="h4" fontSize="18px">
          「{title}」
        </Typography>
      </Stack>
      <Box margin="1rem auto" sx={{ display: "flex" }} alignItems="center">
        <Avatar
          alt={`${name}avatar`}
          src={avatarUrl}
          sx={{ width: "48px", height: "48px" }}
        />
        <Box margin="0 1rem">
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "1.6",
              color: customColors.textColor.sub
            }}
          >
            {organization}
          </Typography>
          <Typography fontWeight="bold" fontSize="18px" lineHeight="160%">
            {name}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default CaseItem;
