import FirstViewImage1 from "assets/teacherLp/lp-teacher-top-1.png"; // 左側の画像
import FirstViewImage2 from "assets/teacherLp/lp-teacher-top-2.png"; // 右側の画像
import FirstViewImage3 from "assets/teacherLp/lp-teacher-top-3.png"; // 下側の画像

import type React from "react";
import { Box, useTheme, Typography, useMediaQuery, Link } from "@mui/material";
import Button from "components/atoms/mui/Button";
import { useHistory } from "react-router-dom";

const FirstView: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const typographyStyle = {
    fontSize: "calc(1rem + 2vw) !important",
    backgroundColor: "#ffffff",
    fontFamily: "'ヒラギノ丸ゴ ProN', 'Hiragino Maru Gothic ProN', sans-serif",
    letterSpacing: "0.1em",
    padding: "1rem",
    borderRadius: "8px",
    whiteSpace: "nowrap"
  };

  const imgStyle = {
    flex: "1",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: isMobile ? "50%" : "100%", // スマホでは高さを半分に
    width: isMobile ? "100%" : "50%" // PCでは幅を半分に
  };

  return (
    <>
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row", // スマホでは上下、PCでは左右に並べる
          alignItems: "center",
          justifyContent: "center",
          height: "480px",
          mt: "56px", // ヘッダーの高さ分だけ下にずらす
          position: "relative", // 子要素の絶対配置を許容
          overflow: "hidden"
        }}
      >
        {/* 背景画像 */}
        <Box
          sx={{
            ...imgStyle,
            backgroundImage: `url(${FirstViewImage1})`
          }}
        />
        <Box
          sx={{
            ...imgStyle,
            backgroundImage: `url(${FirstViewImage2})`
          }}
        />

        {/* 中央テキスト */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            color: theme.palette.text.primary,
            alignItems: "center",
            zIndex: 2, // テキストとボタンが最前面
            gap: 1
          }}
        >
          <Typography variant="h1" sx={typographyStyle}>
            複業で
            <Box component="span" color="#f66800">
              先生をしたい
            </Box>
            人と
          </Typography>
          <Typography variant="h1" sx={typographyStyle}>
            <Box component="span" color="#f66800">
              学校
            </Box>
            をつなぐ
          </Typography>
          <Button
            color="primary"
            size="large"
            sx={{ width: "320px", height: "64px", mt: "12px" }}
            onClick={() => history.push("/signup?active=teacher")}
          >
            先生をやりたい方はこちら
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: isMobile ? "-45px" : "-70px",
          mb: isMobile ? "48px" : "120px",
          zIndex: 2,
          marginX: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "32px"
        }}
      >
        <img
          src={FirstViewImage3}
          alt=""
          width={isMobile ? "320px" : "520px"}
        />
        <Typography variant="caption">
          学校や塾などで複業先生を導入したい方は
          <Link href="/lp/school">こちら</Link>
        </Typography>
      </Box>
    </>
  );
};

export default FirstView;
