import Box from "@mui/material/Box";

import FAQItem from "features/root/lp/school/molecules/FAQItem";

import type React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import TopPageTitle from "../molecules/TopPageTitle";
import { FAQ_ITEMS } from "../constants";

const Faq: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: 3,
        alignItems: "center",
        justifyContent: "center",
        mb: isMobile ? "48px" : "120px"
      }}
    >
      <TopPageTitle title="FAQ" subText={["よくある質問"]} />
      <Box
        sx={{
          width: "100%",
          margin: { xs: "1rem auto", md: "2rem auto" },
          maxWidth: { xs: "none", md: "600px" }
        }}
      >
        {FAQ_ITEMS.map((item, index) => (
          <FAQItem key={index} {...item} />
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
