import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Dayjs } from "dayjs";
import dayjs from "libs/dayjs";
// import dayjs, { ConfigType } from "dayjs";

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";

import FormLabel from "components/atoms/FormLabel";
import DatePicker from "components/atoms/fields/DatePicker";
import Select from "components/atoms/fields/Select";

import get from "lodash/get";
import { FormControl, Stack, useMediaQuery } from "@mui/material";
import {
  hoursOptions as hoursOptionsDefault,
  minutesOptions as minutesOptionsDefault
} from "constants/selectOptions";

type FormDatetimeProps = {
  label: string;
  name: string;
  supplementalText?: string;
  isRequired?: boolean;
  minDate?: Dayjs;
  allowedDates?: Dayjs[];
  noLabel?: boolean;
  hoursOptions?: {
    label: string;
    value: number;
  }[];
  minutesOptions?: {
    label: string;
    value: number;
  }[];
  monitoredTargets?: string[];
};

const FormDatetime: React.FC<FormDatetimeProps> = ({
  label,
  name,
  supplementalText,
  isRequired,
  noLabel,
  minDate,
  allowedDates,
  hoursOptions = hoursOptionsDefault,
  minutesOptions = minutesOptionsDefault,
  monitoredTargets
}) => {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isError = !!get(errors, name);
  const errorMessage = get(errors, name)?.message;

  const handleDateTimeChange = (
    date: Dayjs | null,
    field?: "hours" | "minutes",
    value?: number
  ) => {
    if (!date) return;
    // FIXME: 毎回Dateオブジェクトを生成しているので、最適化が必要
    const currentValue = dayjs(getValues(name)).tz("Asia/Tokyo");
    let updatedValue = currentValue;

    if (field && value !== undefined) {
      updatedValue =
        field === "hours"
          ? updatedValue.hour(value)
          : updatedValue.minute(value);
    } else {
      updatedValue = updatedValue
        .year(date.year())
        .month(date.month())
        .date(date.date());
    }

    updatedValue = updatedValue.second(0);
    setValue(name, updatedValue.format(), { shouldDirty: true });
    trigger(name);

    // MEMO: 各FormFieldコンポーネントに仕込んでもいいかも
    if (monitoredTargets) {
      monitoredTargets.forEach((target) => {
        trigger(target);
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      {label &&
        (!noLabel ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "0.25rem"
            }}
          >
            <FormLabel labelName={label} isRequired={isRequired} />
            {supplementalText && (
              <Typography
                variant="caption"
                sx={{ color: theme.palette.sub.main }}
              >
                {supplementalText}
              </Typography>
            )}
          </Box>
        ) : (
          <Typography fontWeight="bold">{label}</Typography>
        ))}

      <Box>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Stack direction={isMobile ? "column" : "row"} gap={1}>
              <DatePicker
                value={dayjs(field.value)}
                minDate={dayjs(minDate)}
                allowedDates={allowedDates}
                onChange={(date) => handleDateTimeChange(date)}
                error={isError}
                width="100%"
              />
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={dayjs(field.value).hour()}
                  options={hoursOptions}
                  onChange={(e) =>
                    handleDateTimeChange(
                      field.value,
                      "hours",
                      e.target.value as number
                    )
                  }
                  error={isError}
                  width="100%"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={dayjs(field.value).minute()}
                  options={minutesOptions}
                  onChange={(e) =>
                    handleDateTimeChange(
                      field.value,
                      "minutes",
                      e.target.value as number
                    )
                  }
                  error={isError}
                  width="100%"
                />
              </FormControl>
            </Stack>
          )}
        />
      </Box>
      {isError && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errorMessage?.toString()}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormDatetime;
