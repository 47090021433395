import { useAtomValue } from "jotai";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionLoading from "components/atoms/SectionLoading";

import type React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import HowToWriteAccountHolder from "features/teachers/mypage/settings/molecules/HowToWriteAccountHolder";
import { Methods as UpdateMethods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts/_teacherBankAccountId@string";
import { Methods as CreateMethods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts";
import BankAccountDisplay from "./BankAccountDisplay";
import BankAccountCreateForm from "./BankAccountCreateForm";
import BankAccountUpdateForm from "./BankAccountUpdateForm";

import { teacherBankAccountAtom, isEditingAtom } from "../store";

interface BankAccountContentsProps {
  isLoading: boolean;
  handleDelete: () => void;
  createBankAccount: () => void;
  updateBankAccount: () => void;
  createBankAccountForm: UseFormReturn<CreateMethods["post"]["reqBody"]>;
  updateBankAccountForm: UseFormReturn<UpdateMethods["put"]["reqBody"]>;
}

const BankAccountContents: React.FC<BankAccountContentsProps> = (props) => {
  const {
    handleDelete,
    isLoading,
    createBankAccount,
    updateBankAccount,
    createBankAccountForm,
    updateBankAccountForm
  } = props;

  const {
    formState: { isSubmitting: isCreateSubmitting }
  } = createBankAccountForm;
  const {
    formState: { isSubmitting: isUpdateSubmitting }
  } = updateBankAccountForm;

  const teacherBankAccount = useAtomValue(teacherBankAccountAtom);
  const isEditing = useAtomValue(isEditingAtom);

  const getTeacherBankAccountContentType = () => {
    if (isLoading) {
      return <SectionLoading />;
    }

    if (!teacherBankAccount) {
      return (
        <FormProvider {...createBankAccountForm}>
          <form
            onSubmit={createBankAccountForm.handleSubmit(createBankAccount)}
          >
            <BankAccountCreateForm />
          </form>
        </FormProvider>
      );
    }

    if (isEditing) {
      return (
        <FormProvider {...updateBankAccountForm}>
          <form
            onSubmit={updateBankAccountForm.handleSubmit(updateBankAccount)}
          >
            <BankAccountUpdateForm />
          </form>
        </FormProvider>
      );
    }

    return <BankAccountDisplay handleDelete={handleDelete} />;
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        width: "100%"
      }}
    >
      <Typography>
        謝礼は、授業・イベント実施翌月末に登録された口座に振り込ませていただきます。
        <br />
        実施前日までにご入力ください。（口座登録が遅れた場合は、振込も遅れる可能性がございます）
        <br />
        振り込み手数料は、講師の方のご負担になります。
        <br />
        <small>
          弊社からの振込は「GMOあおぞらネット銀行」から行います。ご負担いただく手数料は、登録口座が「GMOあおぞらネット銀行」であれば無料、それ以外だと145円（税込）となります。
        </small>
      </Typography>
      <HowToWriteAccountHolder />
      {getTeacherBankAccountContentType()}
      {(isCreateSubmitting || isUpdateSubmitting) && (
        <SectionLoading hasColor />
      )}
    </Box>
  );
};

export default BankAccountContents;
