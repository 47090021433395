import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import TeacherBox from "features/root/index/molecules/TeacherBox";
import TopPageTitle from "features/root/index/molecules/TopPageTitle";

import type React from "react";
import Button from "components/atoms/mui/Button";
import { useHistory } from "react-router-dom";
import { TEACHERS_ITEMS } from "../constants";

// SwiperCore.use([Pagination, Autoplay]);
SwiperCore.use([Navigation]);

const TeachersBox: React.VFC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const overXs = useMediaQuery("(min-width:480px)");
  const underSm = useMediaQuery("(max-width: 720px)");
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const perView = () => {
    if (isXs) {
      return 1.2;
    }
    if (overXs && underSm) {
      return 1.8;
    }
    if (isSm) {
      return 2.2;
    }
    if (isMd) {
      return 2.7;
    }
    if (isLg) {
      return 3.5;
    }
    return 4.2;
  };

  return (
    <section>
      <Box
        sx={{
          padding: isMobile ? "48px 16px" : "80px",
          backgroundColor: "#FFF6DE"
        }}
      >
        <TopPageTitle
          title="TEACHERS"
          subText={["人それぞれの形で、学校教育を支援することができます"]}
        />
        <Box margin="2rem 0">
          <Swiper slidesPerView={perView()} navigation>
            {TEACHERS_ITEMS.map((item, index) => (
              <SwiperSlide key={index}>
                <TeacherBox
                  teachersVoice={item.teachersVoice}
                  avatar={item.avatar}
                  titles={item.titles}
                  name={item.name}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => history.push("/teachers")}
            sx={{
              display: "block",
              margin: "1.5rem auto",
              width: "90%",
              maxWidth: "480px",
              padding: "calc(1rem + .3vw)"
            }}
          >
            複業先生をもっと見る
          </Button>
        </Box>
      </Box>
    </section>
  );
};

export default TeachersBox;
