import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import type React from "react";
import { Box, Stack } from "@mui/material";

type ProcedureCardProps = {
  number: number;
  image: string;
  title: string;
  desc: string;
};

const ProcedureCard: React.FC<ProcedureCardProps> = (props) => {
  const { number, image, title, desc } = props;

  return (
    <Card elevation={0}>
      <Stack gap={1.5}>
        {/* 背景色のあるBox */}
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            background: "#FFF6DE",
            overflow: "hidden" // 画像がはみ出た場合に見切れる
          }}
          height="157px"
        >
          {/* 左上のナンバリング */}
          <Box
            sx={{
              backgroundColor: "primary.main",
              position: "absolute",
              left: 0,
              top: 0,
              padding: "4px 16px",
              color: "white",
              fontWeight: "bold",
              borderRadius: "0 0 4px 0",
              zIndex: 2
            }}
          >
            {number}
          </Box>

          {/* 画像 */}
          <Box
            sx={{
              position: "absolute",
              top: "26px", // 上26px確保
              left: "50%",
              transform: "translateX(-50%)", // 水平方向の中央寄せ
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "calc(100% - 26px)", // 上部の26px分を引く
              overflow: "hidden" // 見切れるように
            }}
          >
            <img
              src={image}
              alt={title}
              style={{
                maxHeight: "100%",
                maxWidth: "90%",
                objectFit: "cover" // Boxからはみ出ないように調整
              }}
            />
          </Box>
        </Box>

        <Typography
          variant="h3"
          sx={{
            lineHeight: "1.6 !important"
          }}
        >
          {title}
        </Typography>
        <Typography>{desc}</Typography>
      </Stack>
    </Card>
  );
};

export default ProcedureCard;
